/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--table-scrollbar);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--table-scrollbar-hover);
}

.column-header{
  user-select: none;
}

.column-header::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

::-webkit-scrollbar-corner{
  background-color:transparent;
}