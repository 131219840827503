@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');


:root {
  --bg-primary: #FFFFFF;
  --bg-secondary: #F9FAFB;
  --bg-tertiary: #54c45e14;
  --bg-overlay: #FFFFFF60;
  --bg-invoice: #F3F4F6;
  --bg-invoice-button: #F3F4F6;
  --bg-modal: #FFFFFF;
  --bg-icon-main: #D1D5DB;

  --text-primary: #1F2937;
  --text-secondary: #6B7280;
  --text-supporting: #9CA3AF;
  --text-tertiary: #54C45E;
  --text-button :#FFFFFF;

  --border-main: #4B556399;
  --border-secondary: #D1D5DB;
  --border-tertiary: #7FD387;
  --border-table: #E5E7EB;
  --border-overlay: #2B3544;

  --button-primary: #54C45E;
  --button-primary-hover: #275B2C;

  --button-secondary: transparent;
  --button-secondary-hover: #6b728033;

  --button-error: #F87171;
  --button-error-hover: #B91C1C;

  --nav-bg: #2B3544;
  --nav-selected: #54C45E;
  --avatar-bg: #54C45E;
  --avatar-icon: #FFFFFF;
  --nav-icon: #9CA3AF;

  --icon-main: #4B5563;

  --table-scrollbar: #D1D5DB;
  --table-scrollbar-hover: #D1D5DB;
  --table-number-selected: #4B556329;

  --toggle-bg: #D1D5DB;
  --toggle-selected: #54C45E;
  --toggle-hover: #54C45E40;
  --toggle-circle: #FFFFFF;

  --datepicker-selected: #54C45E;
  --datepicker-selected-text: #1F2937;
  --datepicker-hover: #54c45d40;
  --datepicker-middle: #54c45d40;

  --complete: #54C45E;
  --bg-complete: #54C45E26;
  --text-complete:#47A650;
  --border-complete: #54C45E4D;
  --icon-complete: #54C45E;

  --incomplete: #FBBF24;
  --bg-incomplete: #FBBF2426;
  --text-incomplete: #D97706;
  --border-incomplete: #FBBF2466;
  --icon-incomplete: #F59E0B;

  --failed: #EF4444;
  --bg-failed: #EF444426;
  --text-failed: #DC2626;
  --border-failed: #EF44444D;
  --icon-failed: #EF4444;
  
  --mismatch: #F97316;
  --bg-mismatch: #F9731626;
  --text-mismatch: #F97316;
  --border-mismatch: #F973164D;
  --icon-mismatch: #F97316;

  --toast-bg-success: #EAF8EB;
  --toast-icon-success: #37813E;
  --toast-text-success: #37813E;
  --toast-bg-error: #FEE2E2;
  --toast-icon-error: #DC2626;
  --toast-text-error: #DC2626;
}

.dark {
  --bg-primary: #1F2937;
  --bg-secondary: #252F3E;
  --bg-tertiary: #54c45e29;
  --bg-overlay: #1F293760;
  --bg-invoice: #2B3544;
  --bg-invoice-button: #374151;
  --bg-modal: #2B3544;
  --bg-icon-main: #6B7280;

  --text-primary: #FFFFFF;
  --text-secondary: #9CA3AF;
  --text-supporting: #6B7280;
  --text-button :#1F2937;

  --border-main: #9CA3AFCC;
  --border-secondary: #4B5563;
  --border-tertiary: #37813E;
  --border-table: #414B5A;
  --border-overlay: #F3F4F6;

  --avatar-icon: #1F2937;

  --icon-main: #9CA3AF;

  --table-scrollbar: #6B7280;
  --table-scrollbar-hover: #6B7280;
  --table-number-selected: #9CA3AF33;

  --toggle-bg: #6B7280;
  --toggle-selected: #54C45E;
  --toggle-hover: #54C45E40;
  --toggle-circle: #1F2937;

  --complete: #54C45E;
  --bg-complete: #54C45E33;
  --text-complete:#7FD387;
  --border-complete: #54C45E80;
  --icon-complete: #54C45E;

  --incomplete: #FBBF24;
  --bg-incomplete: #FBBF2433;
  --text-incomplete: #FBBF24;
  --border-incomplete: #FBBF2480;
  --icon-incomplete: #FBBF24;

  --failed: #F87171;
  --bg-failed: #F8717133;
  --text-failed: #F87171;
  --border-failed: #F8717180;
  --icon-failed: #F87171;

  --processing: #717ff8;
  --bg-processing: #717ff833;
  --text-processing: #717ff8;
  --border-processing: #717ff880;
  --icon-processing: #717ff8;
    
  --mismatch: #F97316;
  --bg-mismatch: #F9731633;
  --text-mismatch: #F97316;
  --border-mismatch: #F973164D;
  --icon-mismatch: #F97316;

  --toast-bg-success: #AAE2AF;
  --toast-icon-success: #1E4521;
  --toast-text-success: #1E4521;
  --toast-bg-error: #FCA5A5;
  --toast-icon-error: #7F1D1D;
  --toast-text-error: #7F1D1D;
}

body {
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
  margin: 0;
  font-family: "Noto Sans", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}


/* Styles for the Apex Chart Tooltip - it is appended to the body so we need to have the styles in the Global file */
.apexcharts-tooltip {
  background: var(--bg) !important;
  border-color: var(--border-main) !important;
}

/* Style the export box container */
.apexcharts-menu {
  background-color: var(--bg) !important;
  color: var(--text-primary) !important;
  border: 1px solid var(--border-main) !important;
  text-align: left !important;
}

/* Style menu items on hover */
.apexcharts-menu-item:hover {
  background-color: var(--bg-secondary) !important;
}